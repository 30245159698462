import centros from "../../assets/centros.svg";

const CentrosConciliacionLocales = ({
  cardData: {
    showConciliacionColec,
    altConciliacionColec,
    showConciliacionInd,
    altConciliacionInd,
    ConciliacionIndividual,
    ConciliacionColectiva,
    name,
  },
}) => {
  return (
    <div className="row flex-column">
      <div className="col mb-4">
        <div className="row justify-content-center">
          <img
            src={centros}
            alt="Centros de conciliación locales"
            className="w-25"
          />
        </div>
      </div>
      <div className="col">
        {showConciliacionInd && ConciliacionIndividual ? (
          <>
          
            <h6 className="brief text-center">Asuntos individuales</h6>
            <div>
              <div className="row">
                <div class="col">
                  {/* Contenido de la primera columna */}
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.admitidas}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Solicitudes admitidas
                  </div>
                </div>
                <div class="col">
                  {/* Contenido de la segunda columna */}
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.total_tramite}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Asuntos en trámite
                  </div>
                </div>
                <div class="col">
                  {/* Espacio vacío */}
                  
                 
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.total_conciliados}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Asuntos conciliados
                  </div>
                </div>
              </div>
              <div className="row">
              <div class="col">
              <br></br>
                  {/* Contenido de la tercera columna */}
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.total_no_conciliados}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Asuntos no conciliados
                  </div>
                </div>
                
                <div class="col">
                  {/* Contenido para colocar debajo del espacio vacío */}
                  
                </div>
                <div class="col">
                  {/* Otro contenido para colocar debajo del espacio vacío */}
                  <br></br>
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.porcentaje_conciliacion}%
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Porcentaje de conciliación
                  </div>
                </div>
              </div>
              <div className="row">
                <div class="col">
                  {/* Espacio vacío */}
                  <br></br>
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.total_conciliadores}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Total de conciliadores
                  </div>
                </div>
                <div class="col">
                  {/* Contenido para colocar debajo del espacio vacío */}
                  <br></br>
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.hombres}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Hombres
                  </div>
                </div>
                <div class="col">
                  {/* Otro contenido para colocar debajo del espacio vacío */}
                  <br></br>
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.mujeres}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Mujeres
                  </div>
                </div>
              </div>
              <div className="row">
                <div class="col">
                  {/* Espacio vacío */}
                  <br></br>
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.total_notificadores}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Total de Notificadores
                  </div>
                </div>
                <div class="col">
                  {/* Contenido para colocar debajo del espacio vacío */}
                  <br></br>
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.hombres_notificadores}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Hombres
                  </div>
                </div>
                <div class="col">
                  {/* Otro contenido para colocar debajo del espacio vacío */}
                  <br></br>
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionIndividual.mujeres_notificadoras}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Mujeres
                  </div>
                </div>
              </div>
            </div>
            {/* {name === "Durango" && (
              <p className="mt-2" style={{ fontSize: "smaller" }}>
                * Nota: Estos datos se encuentran pendientes de revisión por el
                Centro de Conciliación
              </p>
            )} */}
          </>
        ) : (
          <div className="row flex-column">
            <div className="col">
            </div>
          </div>
        )}
        {showConciliacionColec && ConciliacionColectiva && (ConciliacionColectiva.admitidas !== 0 || ConciliacionColectiva.total_tramite !== 0 || ConciliacionColectiva.total_conciliados !== 0 )? (
          <>
            <h6 className="brief text-center mt-3">Asuntos colectivos</h6>
            <div className="row justify-content-center">
              <div className="col-md-3">
                <div className="row flex-column justify-content-between align-items-center">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionColectiva.admitidas}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Solicitudes admitidas
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row flex-column justify-content-between align-items-center">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionColectiva.total_tramite}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Asuntos en trámite
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row flex-column justify-content-around align-items-center">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {ConciliacionColectiva.total_conciliados}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Asuntos conciliados
                  </div>
                </div>
              </div>
              {/*  <div className='col-md-3'>
            <div className='row flex-column justify-content-around align-items-center'>
              <div>
               <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>70 <span style={{ fontSize: 'small' }}>%</span></h3>
              </div>
              <div className='text-center' style={{ fontSize: 'smaller' }}>
                Porcentaje de conciliación
              </div>
            </div>
          </div> */}
            </div>
          </>
        ) : (
          <div className="row flex-column">
            <div className="col">
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CentrosConciliacionLocales;
